import React from "react"
import { graphql, Link } from "gatsby"
import { Col, Container, Row } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import "../assets/styles/index.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import SEO from "../components/SEO/seo"
import { componentResolverFromMap, withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"
import PrismicService from "./{PrismicService.url}"
import PrismicArticle from "./{PrismicArticle.url}"
import PrismicAffiliate from "./{PrismicAffiliate.url}"
import PrismicAppendix from "./{PrismicAppendix.url}"
import Index from "./index"

const PageError = ({ data: { image } }) => {
  return <>
    <SEO
      noindex={true}
    />
    <div className="back-to-home rounded d-none d-sm-block">
      <Link to="/" className="btn btn-icon btn-soft-primary">
        <i>
          <FeatherIcon icon="home" className="icons"/>
        </i>
      </Link>
    </div>

    <section className="bg-home d-flex align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={12} className="text-center">
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              className="img-fluid"
              alt=""/>
            <div className="text-uppercase mt-4 display-3">Oh ! non</div>
            <div className="text-capitalize text-dark mb-4 error-page">
              Page Non Trouvée
            </div>
            <p className="text-muted para-desc mx-auto">
              Il semble que la page que vous avez demandée n'existe plus. Ce
              n'est pas grave, le plus simple est de tester directement{" "}
              <span className="text-primary font-weight-bold">Bizyness</span>,
              c'est gratuit et sans engagement !
            </p>
          </Col>
        </Row>

        <Row>
          <Col md="12" className="text-center">
            <Link to="/" className="btn btn-outline-primary mt-4 mr-1">
              Retourner à l'accueil
            </Link>
            <Link
              to="/creation-compte/"
              className="btn btn-primary mt-4 ml-2"
            >
              Essai gratuit
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  </>
}

export default withPrismicUnpublishedPreview(
  PageError,
  [
    {
      repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
      linkResolver,
      componentResolver: componentResolverFromMap({
        service: PrismicService,
        article: PrismicArticle,
        affiliate: PrismicAffiliate,
        homepage: Index,
        appendix: PrismicAppendix
      })
    }
  ]
)

PageError.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object.isRequired
  }).isRequired
}

export const pageQuery = graphql`query PageError {
  image: file(relativePath: {eq: "404.png"}) {
    childImageSharp {
      gatsbyImageData(width: 420, quality: 90, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`