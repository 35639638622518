import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import Header from "../components/header"
import { graphql } from "gatsby"
import CTA from "../components/cta"
import SEO from "../components/SEO/seo"
import FeatureRight from "../components/featureRight"
import FeatureLeft from "../components/featureLeft"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"

const ServicePage = ({ data: { prismicService } }) => {

  const features = []
  prismicService.data.body.map(function(featureItem, key) {
    features.push({
      imageSrc: featureItem.primary.image.localFile.childImageSharp.gatsbyImageData,
      imageAlt: featureItem.primary.image.alt,
      title: featureItem.primary.name,
      content: featureItem.primary.content.raw
    })
  })

  return (
    <Layout>
      <SEO
        title={prismicService.data.seotitle}
        desc={prismicService.data.seodescription}
      />
      <Header
        title={prismicService.data.title}
        isBig={true}
        subtitle={prismicService.data.subtitle}
      />
      <section className="section overflow-hidden">
        {features.map((featureItem, key) =>
          <Container className={`${key > 0 ? "" : "pb-5 mb-md-5"}`}>
            {key % 2 === 0 ? <FeatureLeft featureDetails={featureItem}/> :
              <FeatureRight featureDetails={featureItem}/>}
          </Container>
        )}
        <CTA id={"service"}/>
      </section>
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    prismicService: PropTypes.object.isRequired
  }).isRequired
}

export default withPrismicPreview(ServicePage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver
  }
])

export const pageQuery = graphql`
  query ServiceQuery($id: String) {
    prismicService(id: { eq: $id }) {
      _previewable
      uid
      data {
        title
        subtitle
        seotitle
        seodescription
        body {
          ... on PrismicServiceDataBodyFeatureItem {
            primary {
              name
              content {
                raw
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 800, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`