import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../components/SEO/seo"
import ArticlesHtmlSerializer from "../utils/ArticlesHtmlSerializer"
import { RichText } from "prismic-reactjs"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/LinkResolver"
import { getTrialDays } from "../assets/javascript/affiliate"
import SignupForm from "../components/signupForm"

const ArticlePage = ({ data: { prismicArticle } }) => {

  return (
    <Layout>
      <SEO
        title={prismicArticle.data.seotitle}
        desc={prismicArticle.data.seodescription}
        article
        node={prismicArticle}
      />
      <section className="bg-half bg-light d-table w-100">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="text-center">
              <div className="page-next-level">
                <h1 className={"mb-4 big-font"}>{prismicArticle.data.title}</h1>
                <p className="text-muted mx-auto mb-0 big-text">{prismicArticle.data.subtitle}</p>
                <ul className="list-unstyled mt-4">
                  <li className="list-inline-item h6 date text-muted"><i
                    className="mdi mdi-calendar-check"></i> {prismicArticle.first_publication_date}</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <Container>
          <Row>
            <Col lg="12">
              <Card className="blog blog-detail border-0 shadow rounded">
                <GatsbyImage
                  image={prismicArticle.data.image.localFile.childImageSharp.gatsbyImageData}
                  alt={prismicArticle.data.image.alt}
                  className="img-fluid rounded-top"/>
                <CardBody className="content">
                  <RichText render={prismicArticle.data.content.raw} htmlSerializer={ArticlesHtmlSerializer}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h2 className="title mb-4">
                  Besoin d'aide pour gérer simplement votre entreprise ?
                </h2>
                <p className="text-muted para-desc mx-auto mb-0">
                  Rejoignez nos
                  centaines de clients
                  déjà satisfaits ! Essayez {" "}
                  <span className="text-primary font-weight-bold">Bizyness</span>{" "}
                  gratuitement pendant {getTrialDays()} jours, sans engagement ni carte de crédit.
                </p>

                <Row className="mt-4 justify-content-center">
                  <Col md="9" lg="7" xl="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <SignupForm id={"article"}/>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    prismicFeature: PropTypes.object.isRequired
  }).isRequired
}

export default withPrismicPreview(ArticlePage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver
  }
])

export const pageQuery = graphql`
  query ArticleQuery($id: String) {
    prismicArticle(id: { eq: $id }) {
      _previewable
      uid
      first_publication_date(formatString: "DD MMMM YYYY", locale: "fr")
      schemaPublicationDate: first_publication_date(formatString: "YYYY-MM-DD")
      schemaModificationDate: last_publication_date(formatString: "YYYY-MM-DD")
      data {
        title
        subtitle
        seotitle
        seodescription
        content {
          raw
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          alt
        }
      }
    }
  }
`